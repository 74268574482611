(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('FormRecordSearcherController', FormRecordSearcherController)

    FormRecordSearcherController.$inject = [
      '$window',
      '$scope',
      '$q',
      '$timeout',
      '$rootScope',
      'form',
      'permissions',
      'Principal',
      'LoginService',
      'Pick',
      'FormUser',
      'ActionHelper',
      'FormHelper',]

    function FormRecordSearcherController (
      $window,
      $scope,
      $q,
      $timeout,
      $rootScope,
      form,
      permissions,
      Principal,
      LoginService,
      Pick,
      FormUser,
      ActionHelper,
      FormHelper) {
        var vm = this;
        vm.collapsedPanel = true;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.form  = form;
        vm.users = [];
        vm.selectedCreators = [];
        vm.selectedPicksColor = {field: undefined, object: undefined, colors: []};
        vm.selectedPicksIcon = {field: undefined, object: undefined, icons: []};
        vm.itemsFilterable = [];
        vm.totalPicks;
        vm.findError = false;
        vm.fullFormLoaded = true;
        vm.createdDateTo;
        vm.filters = {};
        vm.filtersSpecific = {};
        vm.colorsUserState = {};
        vm.iconsUserState = {};
        vm.colorsActionState = {};
        vm.iconsActionState = {};
        vm.toClearFilter = [];
        vm.polygonPath = undefined;  
        vm.permissions = permissions;
        vm.width = $window.innerWidth;
        if (vm.width > 800) {
          vm.viewType = 'map_list';
        } else {
          vm.viewType = 'list';
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        angular.element($window).bind('resize', function() {
          vm.width = $window.innerWidth
          if (vm.width < 800 && vm.viewType == 'map_list') {
            vm.viewType = 'list';
          }
        })
        
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function getPicks(){
          var deferred = $q.defer();
          if (vm.polygonPath) {
            if (vm.filters.picksIcon) {
              var picksIcon = angular.copy(vm.filters.picksIcon);
              delete vm.filters.picksIcon;
            }
            if (vm.filters.picksColor) {
              var picksColor = angular.copy(vm.filters.picksColor);
              delete vm.filters.picksColor;
            }
            delete vm.filters.formRecordIds;
          }
          Pick.query(vm.filters, function(response, headers ){
            vm.picks = response;
            vm.needUpdate = Math.random();
            vm.totalPicks = parseInt(headers('X-Total-Count'))
              $timeout(function() {
                if (vm.polygonPath) {
                  vm.polygonPath.needUpdate = !vm.polygonPath.needUpdate;
                  vm.filters.picksColor = picksColor;
                  vm.filters.picksIcon = picksIcon;
                  $timeout(function(){
                    vm.filters.formRecordIds = vm.selectedFormRecords.toString() != '' ? vm.selectedFormRecords.toString() : null;
                    Pick.query(vm.filters, function(resp, headers) {
                      vm.picks = resp;
                      vm.totalPicks = parseInt(headers('X-Total-Count'))
                      deferred.resolve();
                    })
                  }, 1000)
                } else {
                  deferred.resolve();
                }
              }, 100) 
          });
          return deferred.promise;
        }

        function getActions(){
          var deferred = $q.defer();
          ActionHelper.getActionsOfFormWithNullAction(vm.form.id).then(function(response) {
            vm.actionsToCount = angular.copy(response);
            var temp = angular.copy(vm.actionsToCount);
            temp.pop();
            ActionHelper.filterActionsManualByUser(temp, vm.account.id).then(function(actions) {
              vm.actions = angular.copy(actions);
              deferred.resolve();
            });
          })
          return deferred.promise;
        }

        vm.findPicks = function(){
          var deferred = $q.defer();
          if(vm.form!=undefined){
            $rootScope.showLoading("Buscando respuestas...");
            vm.setFilters();

            getPicks().then(function(){
              $rootScope.hideLoading();
              deferred.resolve();
            })
          }

          vm.updaterCounts();
          return deferred.promise;
        }

        vm.addMapFilter = function(formRecordIds, path){
          if(formRecordIds){
            vm.selectedFormRecords = formRecordIds;
            vm.polygonPath = path;
          }else{
            delete vm.selectedFormRecords;
          }
        }

        vm.changeTab = function(tab) {
          vm.currentTab = tab;
        }



        function loadForm(){
          if (vm.form) {
            $rootScope.showLoading('Cargando campos...');
            vm.filters = {};
            vm.filters.formId = vm.form.id;
            vm.totalPicks = undefined;
            vm.filtersSpecific = {};
            vm.itemsFilterable = [];
            FormHelper.getFormWithExternalOptions(vm.form.id).then(
              function(f) {
                vm.form = f;
                FormHelper.getUsers(vm.form).then(function(users) {
                  vm.users = angular.copy(users);
                });
                FormHelper.getFormUserRelationOfUser(vm.form, vm.account.id).then(function(formUser) {
                  vm.formUser = angular.copy(formUser);
                });
                FormHelper.getItemsThatCanBeFilter(vm.form).then(
                  function(items) {
                    vm.itemsFilterable = angular.copy(items);
                    angular.forEach(vm.itemsFilterable, function(item) {
                      if (item.type == 'select') {
                        if (item.parentItemId) {
                          item.disable = true;
                          item.values = [];
                        } else {
                          item.values.push({ value: null, label: 'Sin valor'});
                        }
                      }
                    })
                    $rootScope.hideLoading();
                  }
                )
                getActions();
              }
            );
          }
        }

        /***********************Execute**************************/
        getAccount();
        loadForm();
        /***********************Execute**************************/
    }
})();